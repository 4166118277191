import React, { useEffect, useState } from 'react'
import { Paper, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow, Box, Typography
} from '@mui/material'
import { v4 as uuid } from 'uuid'

interface DashboardTableProps {
    dataList: any
    type: 'newSubscriptions' | 'upgrades' | 'renewals' | 'subscriptionsByProtocolType'
    title: string
}

export const DashboardTable: React.FC<DashboardTableProps> = (props) => {

    const { dataList, type, title } = props

    console.log({ dataList })

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    interface Column {
        id: 'subscriptionType' | 'date' | 'name' | 'email' | 'currency' | 'price' | 'count' | 'protocolType'
        label: string;
        minWidth?: number;
        align?: 'right';
        format?: (value: number | string) => string;
      }

    const columns = (): Column[]=> {
        if (type === 'renewals' || type === 'upgrades') {
        return [
        { id: 'subscriptionType', label: 'Subscription Type' },
        { id: 'date', label: 'Date' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email'},
      ]
    } else if (type === 'subscriptionsByProtocolType') {
      return [
        { id: 'protocolType', label: 'Protocol Type'},
        { id: 'count', label: 'Count'}
      ]
    } else {
        return [
            { id: 'subscriptionType', label: 'Subscription Type' },
            { id: 'date', label: 'Date' },
            { id: 'name', label: 'Name' },
            { id: 'email', label: 'Email'},
            { id: 'currency', label: 'Currency'}
        ]
    }
    }


  return (
    <Box sx={{ width: '80%' }}>
    <Typography variant="h5" sx={{ marginTop: 10}}>{title}</Typography>
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
       <TableContainer sx={{ maxHeight: 440 }}>
       <Table stickyHeader aria-label="sticky table">
       <TableHead>
            <TableRow>
              {columns().map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={uuid()}>
                    {columns().map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
       </Table>
       </TableContainer>
       <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </Box>
  )
}

export default DashboardTable