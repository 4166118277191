import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { searchCustomer, adjustVideoPTSessions, getNotes, addPhysioToCustomer, removePhysioToCustomer, deleteNote, deleteCustomer, byPassSubscription } from "../api/customer"
import { fetchPhysios } from "../api/admin"
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Brush
} from "recharts"
import dayjs from "dayjs"
import Checkbox from '@mui/material/Checkbox';
import TextEditor from "../components/TextEditor"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  LinearProgress,
  TextField
} from "@mui/material"
import { useAuth } from '../context/authContext'
import { font } from '../themes/fonts'
import { v4 as uuidv4 } from 'uuid';
import Modal from '@mui/material/Modal';

interface KeyStats {
  id: number | null
  protocol_type_id: number | null
  injury_date: string | null
  surgery_date: string | null
  current_progress_stage_id: number | null
  currentWeek: number | null
  stepsComplete: number
}

interface LocationPhysio {
  assignedPhysio: null | number
}

interface Protocol {
  protocolName: string
  protocolTypeName: string
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Customers({ ...props }) {

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customer, setCustomer] = useState<any>("")
  const [result, setResult] = useState<any>("")
  const [error, setError] = useState<string>("")
  const [userMeasurements, setUserMeasurements] = useState<any>([])
  const [userAchievements, setUserAchievements] = useState<any>("")
  const [videoPTSessions, setVideoPTSessions] = useState<number>(0)
  const [adherence, setAdherence] = useState<any>([])
  const [notes, setNotes] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [byPassSubscriptions, setByPassSubscriptions] = useState<any>({})
  const [metadata, setMetadata] = useState<any>({})
  // const [subApiDataStoreInState, setSubApiDataStoreInState] = useState<any>({})
  const [keyStats, setKeyStats] = useState<KeyStats>({
    id: null,
    protocol_type_id: null,
    injury_date: null,
    surgery_date: null,
    current_progress_stage_id: null,
    currentWeek: null,
    stepsComplete: 0
  })
  const [assignedAsPatient, setAssignedAsPatient] = useState<number | null>(null)
  const [protocol, setProtocol] = useState<Protocol>({ protocolName: 'unknown', protocolTypeName: 'unknown' })
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
  const [isByPassSubModal, setIsByPassSubModal] = useState<boolean>(false)
  const [adminPassword, setAdminPassword] = useState<string>('')

  const { emailParam, id } = useParams()
  const { loggedInUser } = useAuth()
  const navigate = useNavigate()
  // const location = useLocation()
  // const { assignedPhysio } = location.state as any

  useEffect(() => {
    const handleSearch = async (customer: string) => {
      setIsLoading(true)
      const result = await searchCustomer(customer)
      if (result.customer.length > 0) {
        setResult(result.customer[0])
        setAssignedAsPatient(result.customer[0].assigned_physio)
        setVideoPTSessions(result.customer[0].video_pt_sessions)
      } else {
        setResult("")
        setError(`No customer with the email of ${customer} found.`)
      }

      if (result.measurements.length > 0) {
        setUserMeasurements({
          flexion: result.measurements
            .filter((measurement: any) => measurement.measurement_type === 0)
            .reverse(),
          extension: result.measurements
            .filter((measurement: any) => measurement.measurement_type === 1)
            .reverse(),
        })
      }

      if (result.track.length > 0) {
        setKeyStats({
          id: result.track[0].id,
          protocol_type_id: result.track[0].protocol_type_id,
          injury_date: dayjs(result.track[0].injury_date).format('MMM DD, YYYY'),
          surgery_date: dayjs(result.track[0].surgery_date).format('MMM DD, YYYY'),
          current_progress_stage_id: result.track[0].current_progress_stage_id,
          currentWeek: result.week.length > 0 ? result.week[0].week_number : 0,
          stepsComplete: result.steps.flat().filter((step: any) => step.todo_completion_percentage === '1.00').length
        })
      }

      if (result.protocol.length > 0) {
        setProtocol({
          protocolName: result.protocol[0].protocol_name,
          protocolTypeName: result.protocol[0].protocol_type_name
        })
      }
      if (result.bypassSubscription && result.bypassSubscription.is_enabled) {
        setChecked(true)
      } else {
        setChecked(false)
      }
      if (result.bypassSubscription) {
        console.log('bypass subscription', result.bypassSubscription)
        setByPassSubscriptions(result.bypassSubscription ? result.bypassSubscription : null)

        setStartDate(result.bypassSubscription?.start_time ? new Date(result.bypassSubscription?.start_time) : null)
        setEndDate(result.bypassSubscription?.end_time ? new Date(result.bypassSubscription?.end_time) : null)
      } else {
        console.log('No bypass subscription')
      }

      if (result.steps.length > 0) {
        setAdherence(result.steps.flat().map((step: any) => ({ ...step, todo_completion_percentage: Number(step.todo_completion_percentage) * 100 })).sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()))
      }

      if (result.track.length > 0) setUserAchievements(result.track[0])

      if (result.subscriptions.length > 0) setSubscriptions(result.subscriptions)

      if (result.metadata) setMetadata(JSON.parse(result.metadata))
      setIsLoading(false)
    }


    if (emailParam) handleSearch(emailParam)

    let noteData: any
    const notesFetch = async (id: string) => {
      if (id) {
        noteData = await getNotes(parseInt(id))
      }
      setNotes(noteData.notes)
    }
    if (id) notesFetch(id)

  }, [])
  // console.log(byPassSubscriptions, 'byPassSubscriptions')
  // TODO --- move physios to a context
  // useEffect(() => {

  //   const getPhysios = async () => {
  //     const data = await fetchPhysios()
  //     setPhysios(data.physios)
  //   }
  //   getPhysios()
  // }, [])

  // const handlePhysioChange = () => {
  //   console.log('handle change running')
  // }

  const handleAddPhysioToCustomer = async () => {
    if (loggedInUser) {
      const data = await addPhysioToCustomer(loggedInUser.id, result.email)
      if (data.success) {
        setAssignedAsPatient(loggedInUser.id)
      }
    }
  }

  const handleRemovePhysioToCustomer = async () => {
    if (loggedInUser) {
      const data = await removePhysioToCustomer(loggedInUser.id, result.email)
      if (data.success) {
        setAssignedAsPatient(null)
      }
    }
  }

  const handleDeleteNote = async (id: string) => {
    console.log('running handleDeleteNote for id of:', id)
    const data = await deleteNote(id)
    if (data.success) {
      setNotes(notes.filter((note: any) => note.id !== id))
    }
  }

  const getKeyStatHeading = (heading: string) => {
    switch (heading) {
      case "injury_date":
        return "Injury Date"
      case "surgery_date":
        return "Surgery Date"
      case "currentWeek":
        return "Current Week"
      case "stepsComplete":
        return "Days of Exercise"
      default:
        return null
    }
  }

  const showDeleteModal = () => {
    setIsDeleteModal(true)
  }
  const hideDeleteModal = () => {
    setIsDeleteModal(false)
  }

  const showByPassSubModal = () => {
    setIsByPassSubModal(true)
  }
  const hideByPassSubModal = () => {
    setIsByPassSubModal(false)
    // setStartDate(null)
    // setEndDate(null)
  }

  const [checked, setChecked] = React.useState(false);
  // console.log(checked, 'checked check')
  const [startDate, setStartDate] = useState<Date | null>(new Date()); // Specify the type as Date | null
  const [endDate, setEndDate] = useState<Date | null>(new Date()); // Specify the type as Date | null
  const getStartData = dayjs(byPassSubscriptions.start_time ? new Date(byPassSubscriptions.start_time) : 'Start At').format('DD/MM/YYYY');
  const getEndData = dayjs(byPassSubscriptions.end_time ? new Date(byPassSubscriptions.end_time) : 'End At').format('DD/MM/YYYY');
  // console.log(getStartData, 'getStartData')
  const formattedStartDate = dayjs(getStartData, 'YYYY-MM-DD').isValid() ? getStartData : 'Start At';
  const formattedEndDate = dayjs(getEndData, 'YYYY-MM-DD').isValid() ? getEndData : 'End At';

  // console.log(result.id, 'result.id');

  const saveByPassSubModal = async () => {
    const res = await byPassSubscription(result.id, checked, startDate, endDate)
    // setSubApiDataStoreInState(res);
    setByPassSubscriptions(res.bypassSubscription)
    // setStartDate(null)
    // setEndDate(null)
    setIsByPassSubModal(false)
    // console.log(res.bypassSubscription, 'res res res res res resresresresresresresresresresresresresresres')
    // setIsByPassSubModal(false)
  }

  // console.log(subApiDataStoreInState , 'subApiDataStoreInState')

  const handleDateChange = (date: Date | null) => {
    // console.log(date, 'date :::::::::::::')
    setStartDate(date); // No type error because `date` is of type Date | null
  };
  const handleEndDateChange = (date: Date | null) => {
    // console.log(date, 'End date :::::::::::::')
    setEndDate(date); // No type error because `date` is of type Date | null
  };

  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    console.log(event.target.checked, 'event.target.checked')
    setChecked(event.target.checked);
  };


  const handleConfirmDeletion = async () => {
    const res = await deleteCustomer(adminPassword, result.id, loggedInUser ? loggedInUser.id : 0)
    if (res.success === true) {
      navigate(-1)
    }
  }

  console.log({ adherence })

  return (
    <>
      {isLoading
        ?
        <LinearProgress />
        :
        <>


          <Grid container spacing={2}>
            <Modal
              open={isDeleteModal}
              onClose={hideDeleteModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Delete {result.name} ?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  This will delete the user account and cannot be undone. To confirm the deletion of this account, enter the admin password.
                </Typography>
                <TextField id="outlined-basic" label="Password" type="password" variant="outlined" onChange={(e) => setAdminPassword(e.target.value)} />
                <Button onClick={() => handleConfirmDeletion()}>CONFIRM DELETION</Button>
              </Box>
            </Modal>

            <Modal
              open={isByPassSubModal}
              onClose={hideByPassSubModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography style={{ fontWeight: 800, marginBottom: 10 }}>Edit: Bypass Subscription</Typography>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <Typography style={{ marginTop: 8 }}>Enable</Typography>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>

                {checked ? (
                  <div>
                    <div style={{ fontSize: 13 }}>
                      <Typography>Start At</Typography>
                      <DatePicker
                        showIcon
                        placeholderText={formattedStartDate}
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleDateChange(date)} // Pass the handler function to handle date changes
                      />
                    </div>
                    <div style={{ marginBottom: 15, marginTop: 10 }}>
                      <Typography style={{ fontSize: 15 }}>End At</Typography>
                      <DatePicker
                        showIcon
                        placeholderText={formattedEndDate}
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => handleEndDateChange(date)} // Pass the handler function to handle date changes
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div>
                  <Button variant="contained" color="primary" style={{ marginRight: 15 }}
                    onClick={() => saveByPassSubModal()}>Save</Button>
                  <Button variant="contained" color="primary" onClick={() => hideByPassSubModal()}>cancel</Button>
                </div>

              </Box>
            </Modal>

            <div>{customer ? customer.id : null}</div>
            {result ? (
              <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
                <Typography style={{ fontWeight: 800 }}>Info</Typography>
                <Typography>Name: {result.name}</Typography>
                <Typography>Email: {result.email}</Typography>
                {metadata && (
                  <Box>
                    <Typography>Age: {metadata.age ? metadata.age : 'unknown'}</Typography>
                    <Typography>Birthday: {metadata.birthday ? metadata.birthday : 'unknown'}</Typography>
                    <Typography>City: {metadata.city ? metadata.city : 'unknown'}</Typography>
                    <Typography>Country: {metadata.country ? metadata.country : 'unknown'}</Typography>
                    <Typography>Gender: {metadata.gender ? metadata.gender : 'unknown'}</Typography>
                  </Box>
                )}
              </Grid>
            ) : (
              <div>{error}</div>
            )}

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '50px' }}>
              <Typography style={{ fontWeight: 800 }}>
                Subscriptions
              </Typography>
              {subscriptions.length > 0 ? (
                subscriptions.map((subscription: any) => (
                  <Typography key={uuidv4()}>
                    {subscription.product_id} - {dayjs(subscription.created_at).format("MMM DD, YYYY")}
                  </Typography>
                ))
              ) : (
                <Typography>
                  This user has no subscriptions
                </Typography>
              )
              }
            </Grid>

            <Grid lg={3} md={3} xl={3} style={{ marginTop: '50px', marginLeft: '30px' }}>
              {/* <Typography style={{ fontWeight: 800 }}>Protocol</Typography> */}
              <Typography style={{ fontWeight: 800 }}>
                Bypass Subscription
              </Typography>
              {byPassSubscriptions.is_enabled ? (
                <div>
                  <Typography key={uuidv4()}>
                    Bypass subscription is <strong>Enabled</strong>, From <span><strong>{dayjs(new Date(byPassSubscriptions.start_time)).format("MMM DD, YYYY")}</strong></span> to <span><strong>{dayjs(new Date(byPassSubscriptions.end_time)).format("MMM DD, YYYY")}</strong></span>.
                  </Typography>
                </div>
              ) : (
                <Typography>
                  Bypass subscription is <strong>Disabled</strong>.
                </Typography>
              )
              }
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                <Button onClick={() => showByPassSubModal()} size="large" variant="contained" color="primary">Edit</Button>
              </div>
            </Grid>


            <Grid lg={2} md={2} xl={2} style={{ marginTop: '50px', marginLeft: '10px' }}>
              <Typography style={{ fontWeight: 800 }}>Protocol</Typography>
              <Typography>{protocol.protocolName}</Typography>
              <Typography>{protocol.protocolTypeName}</Typography>
            </Grid>
          </Grid>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {
              !assignedAsPatient ?
                <div onClick={handleAddPhysioToCustomer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                  <Button size="large" variant="contained" color="primary">Assign as my patient</Button>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
                  <Typography>{result.name} is currently your patient.</Typography>
                  <Button onClick={handleRemovePhysioToCustomer} size="large" variant="contained" color="warning">Remove from patient list</Button>
                </div>
            }

            {result.video_pt_sessions && result.video_pt_sessions > 0 ? (
              <div>
                <Typography>The user has {result.video_pt_sessions} video PT sessions</Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    type="number"
                    min={0}
                    value={videoPTSessions}
                    onChange={e => setVideoPTSessions(parseInt(e.target.value))}
                    style={{ marginRight: '20px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      adjustVideoPTSessions(videoPTSessions, result.email)
                    }
                  >
                    SET
                  </Button>
                </div>
              </div>
            ) : (
              <div>The user has no video PT sessions</div>
            )}
          </div>

          <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} direction='row' wrap="wrap" columns={6} lg={6} md={4} sm={2}>
            {Object.keys(keyStats).map((stat: string, index: number, array: string[]) => (
              (stat === 'id' || stat === 'current_progress_stage_id' || stat === 'protocol_type_id') ? null :
                <Card key={uuidv4()} variant="outlined" style={{ minWidth: '200px' }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {keyStats[stat as keyof typeof keyStats]}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {getKeyStatHeading(stat)}
                    </Typography>
                  </CardContent>
                </Card>
            ))}
            <Card variant="outlined" style={{ maxWidth: '200px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {dayjs().diff(keyStats.surgery_date, 'days')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Days Since Surgery
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ maxWidth: '200px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {dayjs().diff(keyStats.injury_date, 'days')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Days Since Injury
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {adherence.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Adherence</Typography>
              <div style={{ width: '720px', overflowX: 'scroll', height: 270 }}>
                <BarChart
                  width={adherence.length >= 14 ? adherence.length * 50 : 700}
                  height={250}
                  data={
                    adherence
                      ? adherence.map((step: any) => ({
                        date: dayjs(step.updated_at).format("MM-DD"),
                        Percentage: step.todo_completion_percentage
                      }))
                      : [{ name: "uv", Percentage: 0 }]
                  }
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis style={{ fontFamily: font.regular }} dataKey="date" />
                  <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Percentage Complete', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                  <Tooltip />
                  <Bar dataKey="Percentage" fill='#AAD400' />
                </BarChart>
              </div>
            </div>
          )}

          {userMeasurements.flexion && (
            <div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Flexion</Typography>
                <div style={{ width: '720px', overflowX: 'scroll', height: 300 }}>
                  <BarChart
                    width={userMeasurements.flexion.length >= 14 ? userMeasurements.flexion.length * 50 : 700}
                    height={250}
                    data={
                      userMeasurements.flexion
                        ? userMeasurements.flexion.map((measurement: any) => ({
                          date: dayjs(measurement.created_at).format("MM-DD"),
                          Degree: measurement.measurement,
                        }))
                        : [{ name: "uv", Degree: 0 }]
                    }
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis style={{ fontFamily: font.regular }} dataKey="date" />
                    <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Measurement (degree)', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                    <Tooltip />
                    <Bar dataKey="Degree" fill="#FBB03B" />
                  </BarChart>
                </div>
              </div>
            </div>
          )}
          {userMeasurements.extension && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Extension</Typography>
              <div style={{ width: '720px', overflowX: 'scroll', height: 300 }}>
                <BarChart
                  width={userMeasurements.extension.length >= 14 ? userMeasurements.extension.length * 50 : 700}
                  height={250}
                  data={
                    userMeasurements.extension
                      ? userMeasurements.extension.map((measurement: any) => ({
                        date: dayjs(measurement.created_at).format("MM-DD"),
                        Degree: measurement.measurement,
                      }))
                      : [{ name: "uv", Degree: 0 }]
                  }
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" style={{ fontFamily: font.regular }} />
                  <YAxis style={{ fontFamily: font.regular }} label={{ value: 'Measurement (degree)', angle: -90, position: 'insideBottomLeft', fontFamily: font.regular }} />
                  <Tooltip />
                  <Bar dataKey="Degree" fill="#6BBCD9" />
                </BarChart>
              </div>

            </div>
          )}




          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography style={{ textAlign: 'center', marginTop: '30px', marginBottom: '20px' }}>Notes</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '40px', marginRight: '40px' }}>
              <TextEditor
                customerId={id}
                setNotes={setNotes}
                notes={notes}
              />
              <div style={{ marginBottom: '30px', marginLeft: '30px', padding: '15px' }}>
                {notes.map((note: any) => (
                  <div key={note.id} style={{ border: '1px solid slategrey', padding: '15px', borderRadius: '5px', marginBottom: '10px' }}>
                    <Typography>{note.body}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Typography>Created At: {dayjs(note.created_at).format("DD/MM/YYYY")}</Typography>
                      <Typography style={{ marginLeft: '20px' }}>By: Nirtal Shah - id {note.id}</Typography>
                      <Button onClick={() => handleDeleteNote(note.id)} style={{ marginLeft: '15px' }} variant="contained" color="error">Delete</Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Button onClick={() => showDeleteModal()} variant="contained" color="error" size="large">
            DELETE THIS USER
          </Button>

          {/* <div>Assign a Physiotherapist:</div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Physio</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedPhysio}
          label="Physio"
          onChange={handlePhysioChange}
        >
          {physios.map((physio: any) => (
            <MenuItem value={`${physio.given_name} ${physio.family_name}`}>{physio.given_name} {physio.family_name}</MenuItem>
          ))}
        </Select>
      </FormControl> */}



        </>
      }
    </>
  )
}
