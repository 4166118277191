import React, { useEffect, useState } from "react"
import SearchResult from "../components/SearchResult"
import SearchTable from '../components/SearchTable'
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Checkbox,
  Paper,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Pagination
} from "@mui/material"
import { searchCustomers } from "../api/customer"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import { visuallyHidden } from "@mui/utils"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"

export default function Search() {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [page, setPage] = useState<number>(2)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [searchCount, setSearchCount] = useState<number>(0)
  const [searched, setSearched] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState([])
  const [orderBy, setOrderBy] = useState('name')

  const navigate = useNavigate()

  const handleFormChange = (e: string) => {
    setSearched(false)
    setSearchTerm(e)
    setPage(1)
  }

  const handleSearch = async () => {
    const data = await searchCustomers(searchTerm.trim(), 1)
    setSearchCount(data.count)
    setSearchResults(data.customers)
    setSearched(true)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') handleSearch()
  }

  const convertSearchData = (customers: any) => {
    const tableData = searchResults.map((customer: any) => ({ id: customer.id, name: customer.name !== customer.email ? customer.name : '-', email: customer.email, videoPTSessions: customer.video_pt_sessions, assignedPhysio: customer.assigned_physio, currentTrack: customer.current_track_id }))
    return tableData
  }

  const changeTableOrder = (column: string) => {
    setOrderBy(column)
  }

  useEffect(() => {
    const searchAfterPageChange = async () => {
      const data = await searchCustomers(searchTerm.trim(), page)
      setSearchResults(data.customers)
    }
    searchAfterPageChange()
  }, [page])

  return (
      <Box sx={{ marginTop: "30px" }} mx={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ width: "50%", marginRight: "15px" }}
            size="small"
            onChange={e => handleFormChange(e.target.value)}
            id="outlined-basic"
            label="Name or Email"
            variant="outlined"
            helperText={errorMessage ? errorMessage : ""}
            error={!!errorMessage}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <Button
            variant="contained"
            disabled={searchTerm.length < 3}
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </Box>
        {searched === true && searchResults.length > 0 && (
          <Box>
            <Typography>
              There are {searchCount} results for {searchTerm}
            </Typography>
            <SearchTable
              tableData={convertSearchData(searchResults)}
            />
            <Pagination count={Math.ceil(searchCount / 10)} showFirstButton showLastButton page={page} onChange={(e, pageNumber) => setPage(pageNumber)}/>
          </Box>
        )}
      </Box>
  )
}
