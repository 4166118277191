import React, { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Checkbox,
  Paper,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import { visuallyHidden } from "@mui/utils"
import { Link, useNavigate, Navigate } from "react-router-dom"
import { PropaneSharp } from '@mui/icons-material'
import { usePhysio } from "../context/physioContext"

interface PhysioHash {
  [key: number]: string
}

export default function SearchTable({...props}) {

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [rows, setRows] = useState([])
  const [physioHash, setPhysioHash] = useState<PhysioHash>({})

  interface Data {
    id: string;
    name: string;
    email: string;
    videoPTSessions: number;
    assignedPhysio: string | null;
  }

  type Order = 'asc' | 'desc';

  interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    // children: React.ReactNode; 
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const { tableData } = props

  const navigate = useNavigate()
  

  function createData(
    id: string,
    name: string,
    email: string,
    videoPTSessions: number,
    assignedPhysio: string | null,
  ): Data {
    return {
      id,
      name,
      email,
      videoPTSessions,
      assignedPhysio,
    };
  }

  function descendingComparator(a: any, b: any, orderBy: keyof any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // const rows = tableData.map((item: any) => (
  //   createData(item.name, item.email, item.videoPTSessions, item.assignedPhysio)
  // ))

  const { physios } = usePhysio()
  
  const physioHashData: PhysioHash = {}

  physios && physios.forEach((physio: any) => {
    physioHashData[physio.id] = `${physio.given_name} ${physio.family_name}`
  })


  const getPhysioName = (id: number) => {
    if (physioHash) {
      return physioHashData[id]
    } else {
      return 'unknown'
    }
  }

  useEffect(() => {
    setRows(tableData.map((item: any) => (
      createData(item.id, item.name, item.email, item.videoPTSessions, item.assignedPhysio)
    )))
  }, [tableData])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleNavigate = (e: any, name: string, id: string, assignedPhysio: string | null) => {
    navigate(`/search/${name}/${id}` )
  }

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } =props;
    
    const createSortHandler =(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };





    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'videoPTSessions',
      numeric: true,
      disablePadding: false,
      label: 'Video PT Sessions',
    },
    {
      id: 'assignedPhysio',
      numeric: true,
      disablePadding: false,
      label: 'Physiotherapist',
    },
    // {
    //   id: 'subscriber',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Is Subscriber?',
    // },
  ];

  return (
    <TableContainer>
    <Table
      sx={{ minWidth: 750, marginHorizontal: '80px'}}
      aria-labelledby="tableTitle"
      size='medium'
    >
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
        <TableBody>
          {rows.sort(getComparator(order, orderBy))
            .map((row: any, index: any) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                      <TableRow
                        hover
                        onClick={(event: any) => handleNavigate(event, row.email, row.id, row.assignedPhysio)}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">{row.videoPTSessions}</TableCell>
                        <TableCell align="right">{getPhysioName(row.assignedPhysio)}</TableCell>
                      </TableRow>
                  );
                })}
        </TableBody>
    </Table>
  </TableContainer>
  )
}
